import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'


/**
 * 合同签约、履约、违约情况饼图
 */
export const contractStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/contract/contractStatistics',
        params
    })
}

/**
 * 预付合同签约、违约区域分布
 */
export const regionalContractInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/contract/regionalContractInfo',
        params
    })
}

/**
 * 预付合同签约、履约新增趋势
 */
export const contractSignTrend = (params) => {
    return $api.post({
        url: baseURL + '/api/contract/contractSignTrend',
        params
    })
}

/**
 * 预付码累计趋势
 */
export const safeCodeCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/contract/safeCodeCumulative',
        params
    })
}

/**
 * 预付合同按城市/地区统计
 */
export const contractCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/contract/contractCityStatistics',
        params
    })
}

/**
 * 预付合同按领域/行业统计
 */
export const contractIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/contract/contractIndustryStatistics',
        params
    })
}